// ./src/App.tsx
import React, { useRef, useState, useCallback } from "react";
import "react-widgets/styles.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Webcam from "react-webcam";
// import Path from 'path';
import { uploadFileToImage } from './azure-storage-blob';
// import { uploadFileToImage, isStorageConfigured } from './azure-storage-blob';
// import getFaceApi from './faceapi';
import { Pie } from "react-chartjs-2";
// import RecommendBook from './RecommendBook';
import "./App.css";
// import BookSelector from './book_selector.jpeg';

// image読み込み
import AiBookLogo from './images/ai_book_selector_logo.jpg';
import RightLogo from './images/logo.png';
import kawakami from './images/main1.jpg';
import main from './images/bpo_main.jpg';
import error from './images/error.jpg';

// デザイン
import { Button } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const theme = createMuiTheme({
  shape: {
    borderRadius: 16,    // defaults to 4
  },
  palette: {
    primary: {
      main: '#a70000',
    },
    secondary: {
      main: '#f44336',
    },
  },
});
// const videoConstraints = {
//   width: 240,
//   height: 120,
//   // width: 720,
//   // height: 360,
//   facingMode: "user",
// };

// const storageConfigured = isStorageConfigured();

const options = {
  tooltips: {
    callbacks: {
      label: function (value, context) {
        return `${context.datasets[0].data[value.index]}%`;
      }
    }
  },
  legend: {
    // display: false,
    display: false,
    labels: {
      color: 'rgb(255, 99, 132)'
    }
  },
  maintainAspectRatio: false,
  responsive: false,
};

const App = (): JSX.Element => {

  // const [isCaptureEnable, setCaptureEnable] = useState<boolean>(false);
  const webcamRef = useRef<Webcam>(null);
  const [url, setUrl] = useState<string | null>(null);

  // ステータス系
  const [capturing, setCapturing] = useState(false);
  const [errorcount, setErrorCount] = useState(false);
  // const [urllink, setUrlLink] = useState<string | null>("/Capture");
  const [resultstatus, setResultStatus] = useState(false);

  // 本の説明変数
  const [title, setTitle] = useState<string | null>("デフォルトタイトル");
  const [author, setAuthor] = useState<string | null>("デフォルト著者");
  const [rb_url, setRb_url] = useState<string | null>("https://images-na.ssl-images-amazon.com/images/I/41HxJfl4peL._SX338_BO1,204,203,200_.jpg");
  const [ad_url, setAd_url] = useState<string | null>("//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=439393539X&linkId=c603eb0ee1fb735239e56539b490b220");
  const [bookdescription, setBookdescription] = useState<string | null>("デフォルト説明");
  const [booklevel, setBookLevel] = useState<string | null>("1");

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setUrl(imageSrc);
      // setImageSelected(imageSrc);
      const blobname = uploadFileToImage(imageSrc);
      console.log("print blobname");
      blobname.then((bloburl) => {
        // setBloburl(bloburl);
        // console.log(bloburl);
        fetch(`/api/faceapi`, {
          method: 'post',
          body: JSON.stringify(`https://aibookselectorstdata01.blob.core.windows.net/faceimage/${bloburl}?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2023-04-07T17:54:03Z&st=2022-04-07T09:54:03Z&spr=https&sig=bJLUjHrXTq0vj%2B%2F3SXq%2FNMJhUFog20SHTByBv0NLIbY%3D`)
        })
          .then(response => {
            if (response.ok) {
              console.log(response); // 404
              // console.log(response.statusText); // Not Found
              return response.json();

              // return res.json();
              //   console.log(response);
              //   response.text.aaa
              //   return response.text.json(); 
            } else {
              console.log(response); // 404
              return Promise.reject(new Error('エラーです'));
            }
          })
          .then(response => {
            // if (response.text[0] === undefined) {
            //   console.log('undefined');
            //   setErrorCount(true);
            //   return 0;
            // }
            // response.text[0] ?? setErrorCount(true);
            console.log("感情表示");
            console.log(response.text.length)
            if (response.text.length === 0) {
              console.log("エラーに飛ばす。");
              setErrorCount(true);
              // setUrlLink("/Error");
            }
            // 重み付け
            // 軽蔑3倍
            // 恐怖3倍
            // 幸福1/3
            // 驚き1/3
            // 平常1/3
            const emotion_sum = response.text[0].faceAttributes.emotion.anger +
              (response.text[0].faceAttributes.emotion.contempt * 3) +
              response.text[0].faceAttributes.emotion.disgust +
              (response.text[0].faceAttributes.emotion.fear * 3) +
              (response.text[0].faceAttributes.emotion.happiness * 0.333) +
              response.text[0].faceAttributes.emotion.sadness +
              (response.text[0].faceAttributes.emotion.surprise * 0.333) +
              (response.text[0].faceAttributes.emotion.neutral * 0.0333);

            console.log(emotion_sum);
            setAnger(Math.round(response.text[0].faceAttributes.emotion.anger / emotion_sum * 100));
            setContempt(Math.round(response.text[0].faceAttributes.emotion.contempt * 3 / emotion_sum * 100));
            setDisgust(Math.round(response.text[0].faceAttributes.emotion.disgust / emotion_sum * 100));
            setFear(Math.round(response.text[0].faceAttributes.emotion.fear * 3 / emotion_sum * 100));
            setHappiness(Math.round(response.text[0].faceAttributes.emotion.happiness * 0.333 / emotion_sum * 100));
            setSadness(Math.round(response.text[0].faceAttributes.emotion.sadness / emotion_sum * 100));
            setSurprise(Math.round(response.text[0].faceAttributes.emotion.surprise * 0.333 / emotion_sum * 100));
            setNeutral(Math.round(response.text[0].faceAttributes.emotion.neutral * 0.0333 / emotion_sum * 100));

            let emotion_array = [];

            emotion_array.push(["anger", response.text[0].faceAttributes.emotion.anger / emotion_sum * 100]);
            emotion_array.push(["contempt", response.text[0].faceAttributes.emotion.contempt * 3 / emotion_sum * 100]);
            emotion_array.push(["disgust", response.text[0].faceAttributes.emotion.disgust / emotion_sum * 100]);
            emotion_array.push(["fear", response.text[0].faceAttributes.emotion.fear * 3 / emotion_sum * 100]);
            emotion_array.push(["happiness", response.text[0].faceAttributes.emotion.happiness * 0.333 / emotion_sum]);
            emotion_array.push(["sadness", response.text[0].faceAttributes.emotion.sadness / emotion_sum * 100]);
            emotion_array.push(["surprise", response.text[0].faceAttributes.emotion.surprise * 0.333 / emotion_sum * 100]);
            emotion_array.push(["neutral", response.text[0].faceAttributes.emotion.neutral * 0.0333 / emotion_sum * 100]);

            //バブルソートする
            for (let i = 0; i < emotion_array.length - 1; i++) {
              for (let j = 0; j < emotion_array.length - i - 1; j++) {
                if (emotion_array[j][1] > emotion_array[j + 1][1]) {
                  let temp = emotion_array[j];
                  emotion_array[j] = emotion_array[j + 1];
                  emotion_array[j + 1] = temp;
                }
              }
            }

            //判定前チェック
            console.log("判定前値チェック");
            console.log("booklevel:" + booklevel);
            console.log(emotion_array);

if(emotion_array[7][0] === 'anger' && emotion_array[6][0] === 'contempt' && response.text[0].faceAttributes.gender === 'male' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 && ('1' === booklevel || '2' === booklevel)){setTitle('音さがしの本');setAuthor('R.マリー シェーファー');setRb_url('https://images-na.ssl-images-amazon.com/images/I/416csZm4onL._SX317_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=439393539X&linkId=c603eb0ee1fb735239e56539b490b220`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（怒り）が強く現れています。
そんなあなたにおすすめの本は、

『音さがしの本』R.マリーシェーファー　です。

怒りが起こる理由は外側にありますが、どんなことが起きても怒らない平常心を持つこともできます。いまのあなたの周りには、平常心を保てないくらい不協和音やノイズがあるのかもしれません。
そんなときは、意識を落ち着かせて、あなたの周りにある自然の音に耳をすませてみてはいかがでしょうか？
この本には、身近な自然の音・さりげない日常の音の探し方、そこからあなたの柔らかい感性と優しい眼差しを取り戻すヒントが満ちています。
あなたの怒りの原因を冷静に聞き分け、心落ち着く日々を取り戻すきっかけになれば何よりです。`);
}else if(emotion_array[7][0] === 'anger' && emotion_array[6][0] === 'disgust' && response.text[0].faceAttributes.gender === 'male' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 && ('1' === booklevel || '2' === booklevel)){setTitle('音さがしの本');setAuthor('R.マリー シェーファー');setRb_url('https://images-na.ssl-images-amazon.com/images/I/416csZm4onL._SX317_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=439393539X&linkId=c603eb0ee1fb735239e56539b490b220`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（怒り）が強く現れています。
そんなあなたにおすすめの本は、

『音さがしの本』R.マリーシェーファー　です。

怒りが起こる理由は外側にありますが、どんなことが起きても怒らない平常心を持つこともできます。いまのあなたの周りには、平常心を保てないくらい不協和音やノイズがあるのかもしれません。
そんなときは、意識を落ち着かせて、あなたの周りにある自然の音に耳をすませてみてはいかがでしょうか？
この本には、身近な自然の音・さりげない日常の音の探し方、そこからあなたの柔らかい感性と優しい眼差しを取り戻すヒントが満ちています。
あなたの怒りの原因を冷静に聞き分け、心落ち着く日々を取り戻すきっかけになれば何よりです。`);
}else if(emotion_array[7][0] === 'happiness' && emotion_array[6][0] === 'fear' && response.text[0].faceAttributes.gender === 'male' && ('2' === booklevel || '3' === booklevel)){setTitle('本の魔法');setAuthor('司修');setRb_url('https://images-na.ssl-images-amazon.com/images/I/51kVBCkr7HL._SX344_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4560081433&linkId=2031e2519e28fd767e831d7b995f48f5`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（幸福）が強く現れています。

そんなあなたにおすすめの本は、
司修の『本の魔法』です。

（幸福）な状況のときには、心が広くなっているので、さまざまな世界に触れるチャンスです。この本の著者は、多くの書籍を手がける装幀家として知られる方です。本書には、彼が装幀を手がけた本について、どのような考え、過程を辿ってその本を装幀したのかが書かれています。もともと絵画を志していたこともあってか、驚くほど深く本に寄り添い装幀を手がけます。書かれている話はさながら小説のようで、ふだん何気なく目にしている本のカバーに対する見え方が大きく変わってくるように思います。`);
}else if(emotion_array[7][0] === 'neutral' && emotion_array[6][0] === 'fear' && response.text[0].faceAttributes.gender === 'female' && ('2' === booklevel || '3' === booklevel)){setTitle('ラヴクラフト全集');setAuthor('H・P・ラヴクラフト');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/41OdqKFgqEL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4488523080&linkId=5aa7db81406ca85593611a8cfe90a594`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（平常）が強く現れています。
そんなあなたにおすすめの本は、
H・P・ラヴクラフトの『ラヴクラフト全集』です。

（平常）の心持ちのあなたは、もしかしたら、少し日常に退屈しているのかもしれません。
H・P・ラヴクラフトは、怪奇小説・幻想小説の先駆者の一人と言われる作家で、生前は評価されませんでしたが、今ではさまざまなゲームなどにも大きな影響を与える作品を描いた作家です。
この小説に書かれていることはもちろんフィクションですが、そう言われるほどに、これはほんとにあった話なんじゃないか、と疑ってしまうほどの筆力に満ちた作品です。ホラー、怪奇小説という言葉で片付けられないような、この世の真実に触れるかのようなリアリティのある恐怖が描きこまれています。
退屈な日々の中で、この作品が、本という不思議な世界の扉になればうれしく思います。`);
}else if(emotion_array[7][0] === 'neutral' && emotion_array[6][0] === 'happiness' && response.text[0].faceAttributes.gender === 'female' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 && '1' === booklevel){setTitle('好きな理由');setAuthor('高橋みどり');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/419NS6HYQPL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4840111901&linkId=fe915dc68e8e8f4250c47fc822be7f15`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（平常）が強く現れています。

そんなあなたにおすすめの本は、
高橋みどりの『好きな理由』です。

（平常）と出ているあなたは、日々安定しているかと思いますが、そんな日常に彩りを加えてみるのはいかがでしょうか？この本の著者は、フリーのスタイリストの方でとても素敵なセンスの持ち主です。スタイリストという職業は、まさに生活そのものが仕事のような人で、どのような気持ちで、どんなふうに身の回りのものを手に入れると、好きなものに囲まれて過ごすことができるのかが知れる、お手本のような本です。
ぜひこの本をきっかけに好きなものを探しに出かけていただけたらうれしいです。
`);
}else if(emotion_array[7][0] === 'surprise' && emotion_array[6][0] === 'happiness' && response.text[0].faceAttributes.gender === 'male'){setTitle('世界を、こんなふうに見てごらん');setAuthor('日高敏隆');setRb_url('https://images-na.ssl-images-amazon.com/images/I/41GhJRrsb+L._SY344_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4087450279&linkId=7d6b7fe113e8d9cc9c65d11cd78ed2a8`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（驚き）が強く現れています。
そんなあなたにおすすめの本は、

日高敏隆『世界を、こんなふうに見てごらん』です。

あなたはきっと何か最近、驚いたことに出会って、少しどぎまぎしているかもしれませんが、自分にとっての当たり前の世界というのは、ほんの一部で、多くの世界が驚きと不思議に満ちているのかもしれません。
この本の著者の日高敏隆さんは、動物行動学者の第一人者で、虫や動物を見つめるところから、この世界に対する驚きの見方を優しい言葉で伝えてくれます。わかりやすい言葉で綴られていながら、書かれている内容はまさに目からウロコ、読んだ後には、日常の見方が大きく変わるような可能性をもった本です。
この本をきっかけに日常の驚きを楽しめる感覚をもっていただけたらうれしいです。`);
}else if(emotion_array[7][0] === 'contempt' && emotion_array[6][0] === 'neutral' && response.text[0].faceAttributes.gender === 'male' && 25 <= response.text[0].faceAttributes.age && ('2' === booklevel || '3' === booklevel)){setTitle('日記をつける');setAuthor('荒川洋治');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/31YKqwiPzPL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4006021798&linkId=e9e008fa4c994f15f51a1eead9f17967`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（軽蔑）が強く現れています。
そんなあなたにおすすめの本は、

荒川洋治『日記をつける』です。

軽蔑や嫌悪は、周りへの不信感から生まれてきますが、不信というのは心に余裕がないときの疑いから出てくることが多いように思います。心に余裕がないと、つい後ろ向きの考えをしてしまいがちですが、そんなときは日常を客観視する方法の一つとして、日記がおすすめです。著者の荒川洋治さんは、本に対する愛情に満ちた方で、文学に対する深遠な知識も持ち合わせていて、この本では、さまざまな作家の日記を紹介しながら、日記の魅力、言葉を書くことのすばらしさを伝えてくれます。
この本をきっかけに、日記を書くことで、軽蔑や嫌悪の気持ちが少しでも和らぐことを願っています。`);
}else if(emotion_array[7][0] === 'disgust' && emotion_array[6][0] === 'neutral' && response.text[0].faceAttributes.gender === 'male' && 25 <= response.text[0].faceAttributes.age && ('2' === booklevel || '3' === booklevel)){setTitle('日記をつける');setAuthor('荒川洋治');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/31YKqwiPzPL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4006021798&linkId=e9e008fa4c994f15f51a1eead9f17967`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（嫌悪）が強く現れています。
そんなあなたにおすすめの本は、

荒川洋治『日記をつける』です。

軽蔑や嫌悪は、周りへの不信感から生まれてきますが、不信というのは心に余裕がないときの疑いから出てくることが多いように思います。心に余裕がないと、つい後ろ向きの考えをしてしまいがちですが、そんなときは日常を客観視する方法の一つとして、日記がおすすめです。著者の荒川洋治さんは、本に対する愛情に満ちた方で、文学に対する深遠な知識も持ち合わせていて、この本では、さまざまな作家の日記を紹介しながら、日記の魅力、言葉を書くことのすばらしさを伝えてくれます。
この本をきっかけに、日記を書くことで、軽蔑や嫌悪の気持ちが少しでも和らぐことを願っています。`);
}else if(emotion_array[7][0] === 'sadness' && emotion_array[6][0] === 'neutral' && response.text[0].faceAttributes.gender === 'male'){setTitle('真理先生');setAuthor('武者小路実篤');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/51TMJjwLUlL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4101057044&linkId=ab338b066728f493d9cd302cc6b56022`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（悲しみ）が強く現れています。
そんなあなたにおすすめの本は、

武者小路実篤の『真理先生』です。

人生にはいろいろあり、辛いことが続くと、悲しく、やる気がなくなってしまうこともありますが、この本は、そんなときに手にしても再びがんばろうと思わせてくれるような輝きがあります。
作品の中に登場する人々には幸せな人もいれば、不幸な人もいますが、真理先生をはじめ、良き方向を信じて生きる人たちがいることで、皆がそれぞれに自立し、自らにとっての幸せな方向に歩んでいく、そんな幸福な世界が閉じ込めらています。
もちろん、小説と現実は違います。しかし、読んでいると、こんなにうまくいくはずないという気持ちよりも、こういう心持ちでありたいと思わせる方が勝ってくるような、清々しさのある一冊です。
この本によって、あなたの悲しみの気持ちが少しでも癒やされたら幸いです。`);
}else if(emotion_array[7][0] === 'contempt' && emotion_array[6][0] === 'sadness' && response.text[0].faceAttributes.gender === 'female' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 && ('2' === booklevel || '3' === booklevel)){setTitle('いづみ語録');setAuthor('鈴木いづみ');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/41OdqKFgqEL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4892570699&linkId=daaa69da0312bda814ec633d1137499b`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（軽蔑）が見て取れます。
そんなあなたにおすすめの本は、

鈴木いづみさんの『いづみ語録』です。

この本の著者は、独特の経歴を経て、激しい人生を歩んだ作家です。世の中への軽蔑や嫌悪も多く感じていたように思いますが、それを言葉に表現することで生を輝かせ、さまざまな後世の作家に影響を与えました。かつて寺山修司という天才が、自分のことを「職業、寺山修司。」といったように、彼女も、生き方そのものが彼女自身であるとしか言えないような人でした。その言葉は、切れ味するどく、妖刀のように読む人を動揺させます。
彼女の言葉で、あなたの気持ちが少しでも和らいでくれたらうれしいです。`);
}else if(emotion_array[7][0] === 'contempt' && emotion_array[6][0] === 'sadness' && response.text[0].faceAttributes.gender === 'male' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 ){setTitle('雲と鉛筆');setAuthor('吉田 篤弘');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/31WlmCCSzkL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4480683259&linkId=8ff152a60c9ba786f3c3e4f07d03371a`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（軽蔑）が強く現れています。
そんなあなたにおすすめの本は、

吉田篤弘の『雲と鉛筆』です。

現代社会は誰もが忙しく、日常の日々に忙殺されていることがふつうのようになっています。忙しい日々の中では、軽蔑や嫌悪の気持ちが枠のも仕方がないように思います。
そんな現代に比べると、この作品の主人公は、淡々と毎日を繰り返す起伏のない生活をしています。水道も通っていない屋根裏部屋で、小さな本を読み、鉛筆で雲を描き続きます。しかし、同じような毎日も、雲の表情のように日々微妙に異なり、変化していきます。大きな変化をつい求めてしまいますが、小さな本に大きなことが書かれていることがあるように、小さくとも大事なことがあるという、とても大切なことをきづかせてくれるような一冊です。
この本が微かなマッサージのようになって、肩の力が少しでも抜けてくれればうれしく思います。`);
}else if(emotion_array[7][0] === 'disgust' && emotion_array[6][0] === 'sadness' && response.text[0].faceAttributes.gender === 'female' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 && ('2' === booklevel || '3' === booklevel)){setTitle('いづみ語録');setAuthor('鈴木いづみ');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/41OdqKFgqEL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4892570699&linkId=daaa69da0312bda814ec633d1137499b`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（軽蔑）が見て取れます。
そんなあなたにおすすめの本は、

鈴木いづみさんの『いづみ語録』です。

この本の著者は、独特の経歴を経て、激しい人生を歩んだ作家です。世の中への軽蔑や嫌悪も多く感じていたように思いますが、それを言葉に表現することで生を輝かせ、さまざまな後世の作家に影響を与えました。かつて寺山修司という天才が、自分のことを「職業、寺山修司。」といったように、彼女も、生き方そのものが彼女自身であるとしか言えないような人でした。その言葉は、切れ味するどく、妖刀のように読む人を動揺させます。
彼女の言葉で、あなたの気持ちが少しでも和らいでくれたらうれしいです。`);
}else if(emotion_array[7][0] === 'disgust' && emotion_array[6][0] === 'sadness' && response.text[0].faceAttributes.gender === 'male' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 ){setTitle('雲と鉛筆');setAuthor('吉田 篤弘');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/31WlmCCSzkL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4480683259&linkId=8ff152a60c9ba786f3c3e4f07d03371a`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（嫌悪）が強く現れています。
そんなあなたにおすすめの本は、

吉田篤弘の『雲と鉛筆』です。

現代社会は誰もが忙しく、日常の日々に忙殺されていることがふつうのようになっています。忙しい日々の中では、軽蔑や嫌悪の気持ちが枠のも仕方がないように思います。
そんな現代に比べると、この作品の主人公は、淡々と毎日を繰り返す起伏のない生活をしています。水道も通っていない屋根裏部屋で、小さな本を読み、鉛筆で雲を描き続きます。しかし、同じような毎日も、雲の表情のように日々微妙に異なり、変化していきます。大きな変化をつい求めてしまいますが、小さな本に大きなことが書かれていることがあるように、小さくとも大事なことがあるという、とても大切なことをきづかせてくれるような一冊です。
この本が微かなマッサージのようになって、肩の力が少しでも抜けてくれればうれしく思います。`);
}else if(emotion_array[7][0] === 'neutral' && emotion_array[6][0] === 'surprise' && response.text[0].faceAttributes.gender === 'male' && 25 <= response.text[0].faceAttributes.age && ('2' === booklevel || '3' === booklevel)){setTitle('猫');setAuthor('大佛次郎、谷崎潤一郎ほか');setRb_url('https://images-na.ssl-images-amazon.com/images/I/41sX+ucdKdL._SX350_BO1,204,203,200_.jpg');setAd_url(`リンクなし`);setResultStatus(true);setBookdescription(`"いまのあなたの感情には（平常）が強く現れています。
そんなあなたにおすすめの本は、

大佛次郎、谷崎潤一郎などの短編集『猫』です。

心が落ち着いているときには、少し風変わりなものに挑戦してみてもいいかもしれません。
「猫」がテーマになったほんといっても、この本は実は、60年以上前に刊行された本で、昭和を代表する作家たちが自分の猫との交流を書いた随筆がまとまっています。
「猫」という身近な生き物を通して、昭和の名作家たちの感じたことや味わいに触れてみるのはいかがでしょうか。今の文章にはなかなか見出すことが難しい行間ににじみ出る妙味がある作品ばかりです。
気になった作家がいたら、そこからまた新しい文学の世界へ踏み出してもらえたらうれしく思います。`);
}else if(emotion_array[7][0] === 'sadness' && emotion_array[6][0] === 'surprise' && response.text[0].faceAttributes.gender === 'male' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 && ('1' === booklevel || '2' === booklevel)){setTitle('友情');setAuthor('武者小路実篤');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/41fRsvTMZiL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4003105044&linkId=7be1d0084e72a65739c0474af6417a67`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（悲しみ）が強く現れています。
そんなあなたにおすすめの本は、

武者小路実篤『友情』です。

この本は、一言で言ってしまうと、日本の文学史を代表する失恋物語なのですが、主人公の心の揺れる様子がものの見事に描かれていて、100年前に書かれたとは信じられないほどのリアリティで、読んでいて心が痛くなるほどです。
しかし、この物語の作者には、こういった悲しさの中にあっても、人間はそれに耐え乗り越えていく純粋な心があるのだ、ということへの強い信頼が常にあり、悲しい物語でありながら、読み終わったあとには清々しい余韻が残ります。この余韻とともに、あなたの悲しい気持ちも少しでも軽くなればうれしく思います。`);
}else if(emotion_array[7][0] === 'anger' && response.text[0].faceAttributes.gender === 'male' && ('2' === booklevel || '3' === booklevel)){setTitle('言わなければよかったのに日記');setAuthor('深澤七郎');setRb_url('https://images-na.ssl-images-amazon.com/images/I/51txABJNABL._SX346_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4122064430&linkId=22300c3bc7888d0979c3ad6a2800479d`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（怒り）が強く現れています。

そんなあなたにおすすめの本は、
深澤七郎の『言わなければよかったのに日記』です。

怒りを感じる局面はいろいろあると思いますが、自分が思っているふつうと違う、ということが怒りの多くの原因になっているように思います。この本の作者、深澤七郎はとても魅力的な作家ですが、日常の中にいるととてもよく怒られる人のように思います。この本もタイトルにあるように「言わなければよかったのに」と思ったことが日記のように書かれていますが、本を読むという形で距離をとって見ると、その姿はおかしみに溢れ、少し気持ちが理解できます。この本を読んで、こんな考え方をしてしまうんだなぁーと気づくと、身の回りの人たちにも少し優しくなれるように思います。`);
}else if(emotion_array[7][0] === 'fear' && response.text[0].faceAttributes.gender === 'male'){setTitle('大正時代の身の上相談');setAuthor('カタログハウス編');setRb_url('https://images-na.ssl-images-amazon.com/images/I/51HHCC9WE6L._SX334_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4480037101&linkId=7402eb01509278bfddd21daa76a01da1`);setResultStatus(true);setBookdescription(`いまのあなたの感情には(恐怖)が強く現れています。

そんなあなたにおすすめの本は、
カタログハウス編集の『大正時代の身の上相談』です。

(恐怖)と出ているあなたは、もしかしたら何かお悩みごとを抱えられているのかもしれません。この本は、実際に大正時代に新聞に出ていた「身の上相談」をまとめたものです。今読んで、何か解決のきっかけになるということはありませんが、こんなことで悩んでいたのか、というようなことや勘違いのオンパレードでつい笑ってしまいます。読んでいると、時が過ぎれば、悩みなんてものは大したことないのではと思うとともに、昔からいろいろ悩みを抱えている人は多いことを知って、少し気が楽になっていただけたらうれしいです。
`);
}else if(emotion_array[7][0] === 'fear' && response.text[0].faceAttributes.gender === 'female'){setTitle('へろへろ');setAuthor('鹿子裕文');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/515qkSbA4ML._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4480435832&linkId=2f40a07e5889cc76a4c8cf75c660c83d`);setResultStatus(true);setBookdescription(`いまのあなたの感情には(恐怖)が強く現れています。

そんなあなたにおすすめの本は、
鹿子裕文の『へろへろ』です。

何か恐れが心にあるようなときには、周りのものがすべてネガティブに見えてしまったりしますが、この本はその逆、どんな状況も関わる人の心持ちや視点の持ち方でこんなにも世界は明るくなるのか、と驚かされる一冊です。
「よりあい」という介護施設です。ここは「一人のお年寄りが、最期まで自分らしく生きるため」の老人ホームのような場所ですが、最期のときを迎える老人の看取ったり、一般的にはかなりシリアスな局面にも接するような場所です。しかし、さまざまな困難を、笑いと明るさで乗り越えていく、この本はまさに「痛快」。少し落ち込んでいても読むほどに、勇気づけられるように思います。`);
}else if(emotion_array[7][0] === 'happiness' && response.text[0].faceAttributes.gender === 'male' && ('2' === booklevel || '3' === booklevel)){setTitle('変調「日本の古典」講義　身体で読む伝統・教養・知性');setAuthor('内田樹、安田登');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/41r4S1n02zL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4396616333&linkId=fbbdaa9c7edad0e581859573a78be58b`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（幸福）が強く現れています。

そんなあなたにおすすめの本は、
内田樹、安田登の『変調「日本の古典」講義』です。

（幸福）な心持ちのときには、いつも触れないようなさまざまな本を手に取る学びを得るチャンスです。
この本で対談されている内田樹さん、安田登さんともに、いつも愉しそうに話をする方ですが、日本文化の奥深い魅力を2人が語り尽くします。「古典」というと、小難しい印象がありますが、ここでの話は数ページごとに固定概念を覆すような驚きの視点に出会います。とっても濃厚な対談講義を楽しんで受講ください。`);
}else if(emotion_array[7][0] === 'happiness' && response.text[0].faceAttributes.gender === 'female'){setTitle('アーのようなカー');setAuthor('寺井奈緒美');setRb_url('https://images-na.ssl-images-amazon.com/images/I/41HxJfl4peL._SX338_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4863853599&linkId=dd4f8b3bfa9b7d91f6f71891b04c7f6d`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（幸福）が強く現れています。

そんなあなたにおすすめの本は、
寺井奈緒美の『アーのようなカー』です。

この本は、短歌の本を数多く出している書肆侃侃房による新鋭の作家シリーズの一冊です。
読んでいると、あ、これ知っている、と思うような日常の慣れ親しんだ日常が出てくるのですが、そんな普通の景色が寺井さんの言葉で切り取られると、ふわりと色づくような暖かさを感じます。
人生、山あり谷ありですが（幸福）なときにこそ、そのような状態のコツを掴んでおきたいものです。この本を読んでいると、少し日々にかげりを感じたときに、再び幸福感を呼び戻すための日常の眺め方のコツのようなものがつかめるように思います。
体験を重ね合わせるようにして、味わってみていただきたい瑞々しい本です。`);
}else if(emotion_array[7][0] === 'neutral' && response.text[0].faceAttributes.gender === 'male' && ('2' === booklevel || '3' === booklevel)){setTitle('高丘親王航海記');setAuthor('澁澤龍彦');setRb_url('https://images-fe.ssl-images-amazon.com/images/I/41v3siq0igL._SY291_BO1,204,203,200_QL40_ML2_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4167140020&linkId=a6d181663d4c99783f4b2851316ab4f1`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（平常）が強く現れています。

そんなあなたにおすすめの本は、
澁澤龍彦の『高丘親王航海記』です。

（平常）と出ているあなたは、もしかしたら、少し日常に退屈しているかもしれないと思い、めくるめく不思議な世界を冒険できるこの本をお選びしました。著者の澁澤龍彦は、フランスを中心に古今東西の世界中の幻想的な世界を渉猟し、数々の不可思議で怪しく魅惑的な世界を日本に紹介し続けた作家です。読み始めは、有名詞の多さに少し面食らうかもしれませんが、数ページをすぎる頃には、夢と現実が混じり合う異国情緒あふれる世界観の虜になってしまうと思います。
芳醇な香りと味わいが感じられる極上のブランデーのような本書に酔って、日常の退屈がまぎれれば幸いです。`);
}else if(emotion_array[7][0] === 'neutral' && response.text[0].faceAttributes.gender === 'female' && 0 < response.text[0].faceAttributes.age && response.text[0].faceAttributes.age < 49 && ('1' === booklevel || '2' === booklevel)){setTitle('とっておきの話');setAuthor('ちくま文学の森');setRb_url('https://images-na.ssl-images-amazon.com/images/I/21CWY1Q1P2L._BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4480427406&linkId=dc9d2bcfe6d185a0c83d79f6c32c5cf8`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（平常）が強く現れています。

そんなあなたにおすすめの本は、
ちくま文学の森シリーズの『とっておきの話』です。

（平常）と出ているあなたは、日常が安定している反面、起伏がすこしなくなっているのかもしれません。
この本では、広大な海の中から、さまざまな場所の美しい海水を汲んでくるかのように、古今東西の名短編をテーマに沿って集められています。タイトル通り、まさに「とっておきの話」がぎっしりつまってますので、一気に読まずに、日々の時間の合間に、一篇ずつ拾って、砂浜に寄せる波を眺めるように、読んでいただくと、だんだんとあなたの日常にも楽しいビッグウェーブが押し寄せてくるかもしれません。`);
}else if(emotion_array[7][0] === 'sadness' && response.text[0].faceAttributes.gender === 'female' && ('1' === booklevel || '2' === booklevel)){setTitle('うめめ');setAuthor('梅佳代');setRb_url('https://images-na.ssl-images-amazon.com/images/I/61hlO9nVnFL._SY347_BO1,204,203,200_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=489815185X&linkId=ed9aff95b5a43f2ceca550abfb9b4816`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（悲しみ）が強く現れています。

そんなあなたにおすすめの本は、
梅佳代の『うめめ』です。

本書は「写真界の芥川賞」と言われる、木村伊兵衛賞を受賞した本ですが、作者は驚くほどに肩の力が抜けていて、プロフィールに「カメラマンになればイチローとか芸能人と結婚できると思って」とあるのには、つい吹き出してしまいます。中身の写真も日常の断片にこんな愉快な瞬間があるのかと、悲しい気分も消し去ってくれるようなものばかりです。見ていると身の回りの日常の中に可笑しみがあふれていることに気づかされ元気づけられるかのようで、この本で、あなたの周りが少し楽しく見えるようになったら何よりです。`);
}else if(emotion_array[7][0] === 'surprise' && response.text[0].faceAttributes.gender === 'female' && ('2' === booklevel || '3' === booklevel)){setTitle('タイタンの幼女');setAuthor('カート・ヴォネガット');setRb_url('https://m.media-amazon.com/images/I/412gAyrhEIL._SY346_.jpg');setAd_url(`//rcm-fe.amazon-adsystem.com/e/cm?lt1=_blank&bc1=000000&IS2=1&bg1=FFFFFF&fc1=000000&lc1=0000FF&t=interdimensio-22&language=ja_JP&o=9&p=8&l=as4&m=amazon&f=ifr&ref=as_ss_li_til&asins=4150117004&linkId=aaa5523be192ad1c3a94c11bed1db185`);setResultStatus(true);setBookdescription(`いまのあなたの感情には（驚き）が強く現れています。
そんなあなたにおすすめの本は、

カート・ヴォネガット『タイタンの幼女』です。

あなたはきっと何か最近、驚いたことに出会って、少しどぎまぎしているのかもしれません。「事実は小説より奇なり」とは言いますが、小説で驚きの展開に出会っておくと、日常でも平常心を保ちやすくなるようにも思います。
本書は、日本でもファンの多いアメリカの作家の代表作です。読む前にあまり内容をお伝えしにくいですが、まさかと思わせるラストまで楽しく壮大なスケールで読ませてくれる一冊です。ただただ楽しくも読めて、考えるほどに深い、驚きの名作です。`);
          
            } else {
              //ステータスで飛ばすようにする。
              setTitle("");
              setAuthor("");
              setRb_url("");
              setAd_url("");
              setBookdescription("");
              setResultStatus(false);
            }
            setCapturing(true);
            return response.text[0].faceAttributes.emotion;
          });
      });
    }
  }, [webcamRef, booklevel, resultstatus]);

  const HomePage = () => {
    return (
      <>
        <header>
          <div id="header" className="header">
            <a href="/"><img src={AiBookLogo} className="AiBookLogo" alt="AiBookLogo" width="298" height="84" /></a>
            <img src={RightLogo} className="RightLogo" alt="RightLogo" width="298" height="168" />
          </div>
        </header>
        <div id="wrapper" className="wrapper">
          <div id="leftDiv" className="leftDiv">
            <h2 className="text1">ようこそ!</h2>
            <h2 className="text2">AI 選書屋さん が 顔の表情を読み取って</h2>
            <h1 className="text3">あなたにおすすめの本を提案するよ。</h1>
          </div>
          <div id="rightDiv" className="rightDiv">
            <img src={main} className="main" alt="main" />
          </div>
        </div>
        <footer>
          <div id="footer" className="footer">
            <div className="center">
              <ThemeProvider theme={theme}>
                <Button
                  className="HomeButton"
                  onClick={(e) => console.log("test")}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/BookLevel">
                  <p className="buttontext">ここをクリックして進む</p>
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </footer>
      </>
    )
  };

  const handleChange = event => {
    // クリックされたら、valueの値をsetします。
    setBookLevel(event.target.value);
    console.log(booklevel)
  };

  const BookLevelPage = () => {
    return (
      <>
        <header>
          <div id="header" className="header">
            <a href="/"><img src={AiBookLogo} className="AiBookLogo" alt="AiBookLogo" width="298" height="84" /></a>
            <img src={RightLogo} className="RightLogo" alt="RightLogo" width="298" height="168" />
          </div>
        </header>
        <div id="wrapper" className="wrapper">
          <div id="leftDiv" className="leftDiv">
            <div className="balloon">
              <p className="booklevelpagetext1">はじめに、あなたが１ヶ月に読む本の</p>
              <p className="booklevelpagetext2">冊数をおしえてください。</p>
            </div>
            <div id="kawakami_group" className="kawakami_group">
              <div id="kawakamiimage" className="kawakamiimage">
                <img src={kawakami} className="kawakami" alt="kawakami" width="240" height="265" />
              </div>
              <div id="kawakamitext" className="kawakamitext">
                <p className="text1">Book Selector</p>
                <p className="text1">川上洋平</p>
              </div>
            </div>
          </div>
          <div id="rightDiv" className="rightDiv">
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">以下から該当するものをチェックして「次へ」ボタンを押してください</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="radio-buttons-group"
                  value={booklevel}
                  onChange={handleChange}
                >
                  <FormControlLabel value="1" control={<Radio />} label="1ヶ月に0〜1冊程度" />
                  <FormControlLabel value="2" control={<Radio />} label="1ヶ月に2冊〜5冊程度" />
                  <FormControlLabel value="3" control={<Radio />} label="1ヶ月に6冊以上" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <footer>
          <div id="footer" className="footer">
            <div className="center">
              <ThemeProvider theme={theme}>
                <Button
                  className="HomeButton"
                  onClick={(e) => console.log("test")}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/Capture">
                  <p className="buttontext">次へ</p>
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </footer>
      </>
    )
  };


  const RecommendBook = (props) => {
    console.log("resultstatus:" + resultstatus);
    if (resultstatus) {
      return (
        <>
          <header>
            <div id="header" className="header">
              <a href="/"><img src={AiBookLogo} className="AiBookLogo" alt="AiBookLogo" width="298" height="84" /></a>
              <img src={RightLogo} className="RightLogo" alt="RightLogo" width="298" height="168" />
            </div>
          </header>
          <div id="wrapper" className="wrapper">
            <div id="leftDiv" className="leftDiv">
              <div className="balloon">
                <p className="recommendbooktext1">あなたにおすすめの本はこちらです！</p>
              </div>
              <div id="kawakami_group" className="kawakami_group">
                <div id="kawakamiimage" className="kawakamiimage">
                  <img src={kawakami} className="kawakami" alt="kawakami" width="240" height="265" />
                </div>
                <div id="kawakamitext" className="kawakamitext">
                  <p className="text1">Book Selector</p>
                  <p className="text1">川上洋平</p>
                </div>
              </div>
            </div>
            <div id="rightDiv" className="rightDiv">
              <div id="book" className="book">
                <div id="book_description" className="book_description">
                  <div id="title_and_auther" className="book_title_and_auther">
                    <p className="title">『 {props.title}』{props.auther}</p>
                    {/* <p className="auther"> {props.auther}</p> */}
                    <p>-----</p>
                  </div>
                  <div id="description" className="description">
                    <p className="description">{props.description}</p>
                  </div>
                </div>
                <div id="book_image" className="book_image">
                  <div id="select_book" className="select_book">
                    <img src={props.rb_url} alt="SampleBook" width="298" height="474" />
                    <iframe title="ad" className="ad" sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" src={props.ad_url}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div id="footer" className="footer">
              <div className="center">
                <ThemeProvider theme={theme}>
                  <Button
                    className="RecommendBookButton"
                    onClick={() => {
                      setCapturing(false);
                      setUrl("");
                    }}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/">
                    <p className="buttontext">ホーム画面に戻る</p>
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </footer>
        </>
      )
    }
    else {
      return (
        <>
          <header>
            <div id="header" className="header">
              <a href="/"><img src={AiBookLogo} className="AiBookLogo" alt="AiBookLogo" width="298" height="84" /></a>
              <img src={RightLogo} className="RightLogo" alt="RightLogo" width="298" height="168" />
            </div>
          </header>
          <div id="wrapper" className="wrapper">
            <div id="leftDiv" className="leftDiv">
              <div className="balloon">
                <p className="errortext1">お手数をおかけしますが、再度表情の解析をさせてください。</p>
              </div>
              <div id="kawakami_group" className="kawakami_group">
                <div id="kawakamiimage" className="kawakamiimage">
                  <img src={kawakami} className="kawakami" alt="kawakami" width="240" height="265" />
                </div>
                <div id="kawakamitext" className="kawakamitext">
                  <p className="text1">Book Selector</p>
                  <p className="text1">川上洋平</p>
                </div>
              </div>
            </div>
            <div id="rightDiv" className="rightDiv">
              <p className="errortext3">申し訳ありません。</p>
              <p className="errortext4">あなたの表情にあった本のタイトルを選ぶことができませんでした。</p>
              <div id="errorimage" className="errorimage">
                <img src={error} className="error" alt="error" width="203" height="272" />
              </div>
            </div>
          </div>
          <footer>
            <div id="footer" className="footer">
              <div className="center">
                <ThemeProvider theme={theme}>
                  <Button
                    className="RecommendBookButton"
                    onClick={() => {
                      setCapturing(false);
                      setUrl("");
                    }}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/">
                    <p className="buttontext">ホーム画面に戻る</p>
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </footer>
        </>
      )
    }
  };

  const Capture = () => {
    if (!errorcount) {
      return (
        <>
          <header>
            <div id="header" className="header">
              <a href="/"><img src={AiBookLogo} className="AiBookLogo" alt="AiBookLogo" width="298" height="84" /></a>
              <img src={RightLogo} className="RightLogo" alt="RightLogo" width="298" height="168" />
            </div>
          </header>
          <div id="wrapper" className="wrapper">
            <div id="leftDiv" className="leftDiv">
              <div className="balloon">
                {capturing ?
                  <>
                    <p className="capturetext1">なるほど、ふむふむ。</p>
                    <p className="capturetext2">ということは、、、</p>
                  </>
                  :
                  <>
                    <p className="capturetext3">選んでほしい本をイメージしながら、</p>
                    <p className="capturetext4">今のあなたの感情をカメラにおさめてください（誇張気味に）</p>
                  </>
                }
              </div>
              <div id="kawakami_group" className="kawakami_group">
                <div id="kawakamiimage" className="kawakamiimage">
                  <img src={kawakami} className="kawakami" alt="kawakami" width="240" height="265" />
                </div>
                <div id="kawakamitext" className="kawakamitext">
                  <p className="text1">Book Selector</p>
                  <p className="text1">川上洋平</p>
                </div>
              </div>
            </div>
            <div id="rightDiv" className="rightDiv">
              <div id="camera" className="camera">
                {DisplayPicture()}
              </div>
              <div id="graph" className="graph">
                {capturing && <Pie data={graphData} options={options} width={400} height={400} />}
                <div id="graphtext" className="graphtext">
                  <br></br>
                  {capturing && <p className="text2">AIが判定した今のあなたの感情は</p>}
                  {capturing && anger !== 0 ? <p className="text2">怒り : {anger}%</p> : ""}
                  {capturing && contempt !== 0 ? <p className="text2">軽蔑 : {contempt}%</p> : ""}
                  {capturing && disgust !== 0 ? <p className="text2">嫌悪 : {disgust}%</p> : ""}
                  {capturing && fear !== 0 ? <p className="text2">恐怖 : {fear}%</p> : ""}
                  {capturing && happiness !== 0 ? <p className="text2">幸福 : {happiness}%</p> : ""}
                  {capturing && sadness !== 0 ? <p className="text2">悲しみ : {sadness}%</p> : ""}
                  {capturing && surprise !== 0 ? <p className="text2">驚き : {surprise}%</p> : ""}
                  {capturing && neutral !== 0 ? <p className="text2">平常 : {neutral}%</p> : ""}
                  {capturing && <p className="text2">です</p>}
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div id="footer" className="footer">
              {CaptureButton()}
            </div>
          </footer>
        </>
      )
    } else {
      return (
        <>
          <header>
            <div id="header" className="header">
              <a href="/"><img src={AiBookLogo} className="AiBookLogo" alt="AiBookLogo" width="298" height="84" /></a>
              <img src={RightLogo} className="RightLogo" alt="RightLogo" width="298" height="168" />
            </div>
          </header>
          <div id="wrapper" className="wrapper">
            <div id="leftDiv" className="leftDiv">
              <div className="balloon">
                <p className="errortext1">お手数をおかけしますが、再度表情の解析をさせてください。</p>
              </div>
              <div id="kawakami_group" className="kawakami_group">
                <div id="kawakamiimage" className="kawakamiimage">
                  <img src={kawakami} className="kawakami" alt="kawakami" width="240" height="265" />
                </div>
                <div id="kawakamitext" className="kawakamitext">
                  <p className="text1">Book Selector</p>
                  <p className="text1">川上洋平</p>
                </div>
              </div>
            </div>
            <div id="rightDiv" className="rightDiv">
              <p className="errortext3">申し訳ありません。</p>
              <p className="errortext4">AIがあなたの表情の解析を上手くできませんでした</p>
              <div id="errorimage" className="errorimage">
                <img src={error} className="error" alt="error" width="203" height="272" />
              </div>
            </div>
          </div>
          <footer>
            <div id="footer" className="footer">
              <div className="center">
                <ThemeProvider theme={theme}>
                  <Button
                    className="HomeButton"
                    onClick={() => {
                      setErrorCount(false);
                      setUrl("");
                    }}
                    // onClick={(e) => setErrorCount(false)}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/Capture">
                    <p className="buttontext">表情解析画面へ</p>
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </footer>
        </>

      )
    }
  };


  // display take a picture
  const DisplayPicture = () => (
    <>
      {!capturing && (
        <>
          <div>
            <Webcam
              audio={false}
              width={720}
              height={360}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: 720,
                height: 360,
                facingMode: 'user'
              }}
            />
          </div>

        </>
      )}
      {url && (
        <>
          <div>
            <img src={url} alt="Screenshot" />
          </div>
          <div>
          </div>
        </>
      )}
    </>
  )

  const CaptureButton = () => {
    console.log(capturing);
    if (!capturing) {
      return (
        <ThemeProvider theme={theme}>
          <div className="center">
            <Button
              className="CaptureButton"
              onClick={(e) => capture()}
              variant="contained"
              color="primary"
              component={Link}
              to="/Capture">
              <p className="buttontext">画像を認識して次へ</p>
            </Button>
          </div>
        </ThemeProvider>
      )
    } else {
      return (
        <ThemeProvider theme={theme}>
          <div className="center">
            <Button
              className="CaptureButton"
              onClick={(e) => console.log("特になし")}
              variant="contained"
              color="primary"
              component={Link}
              to="/RecommendBook">
              <p className="buttontext">そんなあなたにおすすめの本は</p>
            </Button>
          </div>
        </ThemeProvider>
      )
    }
  }

  const [anger, setAnger] = useState<number | null>(0);
  const [contempt, setContempt] = useState<number | null>(0);
  const [disgust, setDisgust] = useState<number | null>(0);
  const [fear, setFear] = useState<number | null>(0);
  const [happiness, setHappiness] = useState<number | null>(0);
  const [sadness, setSadness] = useState<number | null>(0);
  const [surprise, setSurprise] = useState<number | null>(0);
  const [neutral, setNeutral] = useState<number | null>(0);

  // display
  const graphData = {
    // maintainAspectRatio: false,
    // responsive: false,
    labels: [
      // 軸ラベル
      // 各ラベルを配列にすることで軸ラベルが改行されて表示される
      // [''],
      // ['contempt'],
      // ['disgust'],
      // ['fear'],
      // ['happiness'],
      // ['sadness'],
      // ['surprise'],
      // ['neutral'],
      ['怒り'],
      ['軽蔑'],
      ['嫌悪'],
      ['恐怖'],
      ['幸福'],
      ['悲しみ'],
      ['驚き'],
      ['平常'],
    ],
    datasets: [
      // 表示するデータセット
      {
        backgroundColor: [
          "#ff0000",
          "#2f4f4f",
          "#808080",
          "#b22222",
          "#ffff00",
          "#fafad2",
          // "#191970"
          "#ffa500",
        ],
        borderColor: [
          "#ff0000",
          "#2f4f4f",
          "#808080",
          "#b22222",
          "#ffff00",
          "#fafad2",
          // "#191970"
          "#ffa500",
        ],
        borderWidth: 1,
        label: 'faceapi',
        data: [anger, contempt, disgust, fear, happiness, sadness, surprise, neutral],
      },
    ],
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/BookLevel" element={<BookLevelPage />} />
        <Route path="/Capture" element={<Capture />} />
        <Route path="/RecommendBook" element={<RecommendBook title={title} auther={author} rb_url={rb_url} ad_url={ad_url} description={bookdescription} />} />
        {/* <Route path="/Error" element={<ErrorPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
