// ./src/azure-storage-blob.ts

// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
// import { BlobServiceClient, StorageSharedKeyCredential, ContainerClient} from '@azure/storage-blob';
import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';

// const containerName = `tutorial-container`;
const containerName = `faceimage`;
const sasToken = process.env.REACT_APP_STORAGESASTOKEN;
const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME; 

// </snippet_package>

// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}
// </snippet_isStorageConfigured>

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
const getBlobsInContainer = async (containerClient: ContainerClient) => {
  const returnedBlobUrls: string[] = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
}
// </snippet_getBlobsInContainer>

// <snippet_createBlobInContainer>
const createBlobInContainer = async (containerClient: ContainerClient, file: File) => {
  
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadData(file, options);
}
// </snippet_createBlobInContainer>

// export const uploadFileToImage = async (content: string | null): Promise<string> => {
export const uploadFileToImage = async (content: string | null) => {
  // console.log("uploadFileToImage_start");
  // console.log(content);

  const account: string = storageAccountName;

  // const sharedKeyCredential = new SharedKeyCredential(account, accountKey);
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net/${sasToken}`,
    // sharedKeyCredential
  );

  // console.log(`https://${account}.blob.core.windows.net/${sasToken}`);

  //コンテナを作成した名前に指定
  // const containerName = `tutorial-container`;
  const containerClient = blobServiceClient.getContainerClient(containerName);


  //ファイル整形
  const fileData = content.replace(/^data:\w+\/\w+;base64,/, '');
  const base64_data = Buffer.from(fileData, 'base64');
  // const base64_data = Buffer.from(fileData.toString('base64'),'base64');


  // Create a blob
  // const content: string = url; //変換したbase64文字列を使う
  const blobName = "newblob" + new Date().getTime() + ".jpg";
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  const uploadBlobResponse = await blockBlobClient.upload(base64_data, Buffer.byteLength(base64_data));
  console.log(`Upload block blob ${blobName} successfully`, uploadBlobResponse.requestId);
  return blobName;
}

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file: File | null): Promise<string[]> => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  await containerClient.createIfNotExists({
    access: 'container',
  });

  // upload file
  await createBlobInContainer(containerClient, file);

  // get list of blobs in container
  return getBlobsInContainer(containerClient);
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;

